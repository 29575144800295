import React, { useEffect } from 'react';

import configs from '../../site-config';
import Layout from '../components/layout';
import SEO from '../components/seo';

const DownloadPage = () => {
  useEffect(
    () => {
      const getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

          // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
          return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
        }

        return "unknown";
      }

      switch(getMobileOperatingSystem()) {
        case 'Android':
          if (configs.playstore_link) {
            window.location.href = configs.playstore_link;
          }

          break;

        case 'iOS':
          if (configs.appstore_link) {
            window.location.href = configs.appstore_link;
          }

          break;

        default:
          window.location.href = configs.app_url;
          break;
      }
    },
    [],
  );

  return (
    <Layout>
      <SEO title="Descargar" />
    </Layout>
  );
};

export default DownloadPage;
